<template>
  <div id="accordion">
    <template v-if="!loading">
      <div
        class="card"
        v-for="(pricing, type) in pricings"
        :key="`pricing-${type}`"
      >
        <div class="card-header">
          <router-link :to="{ name: 'admin.pricing.edit', params: { type: type }}" class="float-end">Edit</router-link>
          <a data-bs-toggle="collapse" :href="`#collapse-${type}`" class="h4">
            {{ type }}
          </a>
        </div>
        <div :id="`collapse-${type}`" class="collapse" data-bs-parent="#accordion">
          <div class="card-body">
            <table class="table table-bordered">
              <thead class="text-center">
                <tr>
                  <th width="30%"></th>
                  <th v-for="(price, i) in pricing" :key="`thead-${i}`">
                    <h4 class="text-primary">{{ price.title }}</h4>
                    <h5><sub>KES</sub> {{ price.amount }}</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(line, i) in getContentLines(pricing)"
                  :key="`line-${i}`"
                >
                  <td>{{ line }}</td>
                  <td
                    class="text-center"
                    v-for="(price, j) in pricing"
                    :key="`line-${j}`"
                  >
                    {{ price.content[line] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      pricings: [],
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;

      this.axios.get("/api/v1/admin/pricing").then((response) => {
        this.pricings = response.data.pricings;
        this.loading = false;
      });
    },

    getContentLines(pricing) {
      return Object.keys(pricing[0].content);
    },
  },
};
</script>
